<template>
	<div v-if="creditData.status == 'success'">
		<h1>Credit List</h1><br><br>
		<div class="flex md12 xs12">
			<div class="row">
				<div class="flex xs6 lg3" v-for="(value, key) in creditData.object">
					<va-card class="mb-4 card">
						<p class="display-4" style="color: black;">{{ key }}</p>
						<p>Available Credits - {{value}}</p>
					</va-card>
				</div>
			</div>
		</div>
	</div>
	<div v-else>
		<va-card align="center" justify="center">
		    <h1>Your credit list is empty. Please add transaction</h1>
     	</va-card>
	</div>
</template>

<script>
import Vue from 'vue'
import vueResource from 'vue-resource'
import config from '../../i18n/en.json'

Vue.use(vueResource)

export default {
	created(){
		this.getAllCredit()
	},
	name: 'credit',
	data () {
		return {
			title: '',
			creditData: {},
		}
	},
	methods: {
		getAllCredit () {
	       	this.$http.get(config.menu.host+'/transaction/credit/calculation').then(res => {
	       		this.creditData = res.body
			})
	    },
	}
}
</script>

<style type="text/css">

  .card {
    border: 2px solid #4bf0ca;
  }

</style>